@import 'material-icons/iconfont/material-icons.css';
@import 'chocolat/dist/css/chocolat.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --toastContainerRight: 2rem;
    --toastContainerBottom: 2rem;
    --toastContainerTop: auto;
}

/* Font Definition */
body{
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    @apply font-headline text-base
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

html, body {
  overscroll-behavior-y: none;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-headline
}

h1 {
    @apply text-5xl
}

h2 {
    @apply text-4xl
}

h3 {
    @apply text-3xl
}

h4 {
    @apply text-2xl
}

h5 {
    @apply text-xl
}

h6 {
    @apply text-lg
}

/* Button */
.button {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    @apply leading-none uppercase flex items-center transition-all ease-in-out
}

.button.primary {
    @apply bg-green border border-transparent text-white hover:bg-dark-green disabled:bg-dark-green/40
}

.button.secondary {
    @apply bg-white border border-green text-green hover:bg-green/20 disabled:bg-green/40
}


/* Fonts */
@font-face {
    font-family: "Gill Sans Web";
    src: url("../fonts/gill-sans-regular-web.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Palatino Linotype Web";
    src: url("../fonts/palatino-linotype-regular-web.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

/* Custom Elements */
.banner .underlay {
    max-width: 1920px;
    width: 100%;
    max-height: 100%;
    height: 700px;
    margin: 0 auto;
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,6.6% 88%,0 0);
    clip-path: polygon(0 0,100% 0,100% 100%,6.6% 88%,0 0);
}

.banner .underlay .overlay{
    background: rgba(0,0,0,0.35);
    @apply top-0 left-0 absolute w-full h-full
}

.banner .underlay.no-cut {
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 100%,0 0);
    clip-path: polygon(0 0,100% 0,100% 100%,0% 100%,0 0);
}

.banner .underlay.no-cut .overlay{
    background: rgba(0,0,0,0.55);
}

.banner {
    height: 700px;
    @apply z-0
}

/* Modal Box */
.modal-box {
    @apply bg-white text-black rounded-none overflow-scroll
}

/*Input Styles */ 
input.form-control {
    @apply w-full border border-gray-500 px-2 py-3 my-2
}

.select {
    height: auto;
    min-height: unset;
    @apply block p-0
}

select.form-control {
    @apply w-full border border-gray-500 px-2 py-2
}

/* Flipbook */
.flipbook-wrapper {
  align-items: center;
  display: flex;
  height: 90%;
  justify-content: center;
  margin: 5%;
  width: 90%;
}

.flipbook-aspect {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 768px) {
    .flipbook-aspect {
        padding-bottom: 50%;
    }   
}

.flipbook-aspect-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.flipbook {
  height: 100%;
  transition: margin-left 0.25s ease-out;
  width: 100%;
}

.flipbook .flipbook-page {
  height: 100%;
  width: 100%;
}

.flipbook .flipbook-page img {
  max-width: 100%;
  height: 100%;
}  